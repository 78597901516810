body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media screen and (max-width: 400px) {
  #about,
  #county,
  #contact,
  #footer {
    width: 111%;
  }

  #portfolio {
    width: 110%;
  }
}


.text-left-paragraph { 
  text-align: left;
  margin-bottom: 25px; 
  font-size: large;
}


.endorse-list {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  font-size: medium;
}

.wrapdiv {
  overflow-wrap: break-word;
}

#wrapdiv {
  overflow-wrap: break-word;
}
#concert {
  color: black;
  margin-bottom: 20px;
  text-align: left;
  font-size: 150%;
}

.concert {
  color: black;
  margin-bottom: 20px;
  text-align: left;
  font-size: 150%;
}

#concertitem {
  color: black;
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: left;
  font-size: 100%;
}

.concertitem {
  color: black;
  margin-bottom: 20px;
  margin-right: 20px;
  text-align: left;
  font-size: 100%;
}

table, th, td {
  color: black;
  text-align: left;
  font-size: 90%;
}


#lyrics {
  color: blue;
  margin-bottom: 20px;
  font-size: 150%;
}

.lyrics {
  color: blue;
  margin-bottom: 20px;
  font-size: 150%;
}

a {
  color: blue;
  font-size: 110%;
}


.word {
  color: black;
  font-size: 200%;
}

#word {
  color: black;
  font-size: 200%;
}


.speech {
  color: black;
  margin-bottom: 20px;
  margin-right: 20px;
  font-size: 150%;
}

#speech {
  color: black;
  margin-bottom: 20px;
  margin-right: 20px;
  font-size: 150%;
}

.songtitle {
  color: black;
  font-size: 200%;
  font-family: sans-serif;
  margin-bottom: 20px;
  margin-right: 20px;
}


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.buttonW {
  font-size: 30px;
}
#buttonW {
  font-size: 30px;
}

